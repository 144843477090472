import { type ReactNode } from 'react';
import { useExperiment } from '@hungryroot/experiments';
import { VARIANT_ACCORDION_SECTIONS } from '../accordionSections';

export function useFaqExperimentApi(
  defaultSections: {
    title: string;
    content: ReactNode;
  }[],
  footer: ReactNode
) {
  const variant = useExperiment(157);

  if (variant === undefined) {
    return;
  }

  if (variant < 1) {
    return {
      faqs: defaultSections,
      footer,
      variant,
    };
  }

  return {
    faqs: VARIANT_ACCORDION_SECTIONS,
    variant,
  };
}
