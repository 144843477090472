export const VARIANT_ACCORDION_SECTIONS = [
  {
    title: 'How does it work?',
    content: (
      <>
        Each week, we’ll offer new suggestions based on your tastes, from quick
        breakfasts to nourishing snacks to easy dinners. You can always edit
        your delivery to get exactly what you’d like. Then comes the best part:
        we deliver your groceries and recipes straight to your door. All you
        have to do is cook (most recipes take less than 15 minutes!), eat, and
        enjoy.
      </>
    ),
  },
  {
    title: 'Can I skip or cancel?',
    content: (
      <>
        We offer weekly deliveries to make sure your fridge stays stocked with
        all of your healthy living essentials. It’s easy to skip a delivery,
        reschedule it, or cancel your subscription anytime in the app.
      </>
    ),
  },
  {
    title: 'What foods will I get?',
    content: (
      <>
        That’s entirely up to you! You can take our recommendations or choose
        from ~1,000 groceries and over 15,000 chef-crafted recipes. You’ll find
        eats from fan-favorite brands, like Siggi’s, Kevin’s, and Annie’s, and
        up-and-coming brands we think you’ll love. Our foods always meet our
        super-high standards: made from whole, trusted ingredients and free from
        partially hydrogenated oils, high-fructose corn syrup, and artificial
        colors, sweeteners, and preservatives.
      </>
    ),
  },
  {
    title: 'How much does it cost?',
    content: (
      <>
        You choose exactly how much food you want, and the more you add to your
        delivery, the more you save. (We bet your grocery store doesn’t offer
        that!) You can get dinners as low as $8.99 per serving, lunch for $5.99,
        and breakfast for $3.99. We also offer our Best Price Program, which
        gives you access to brands you love — like Annie’s, Koia, Harvest Snaps,
        and more — at the cheapest prices you’ll find anywhere. (Did you know
        that Hungryroot customers save up to 40% each week by ditching takeout
        and reducing food waste? Nice!)
      </>
    ),
  },
  {
    title: 'Do you have food for kids?',
    content: (
      <>
        Absolutely! Our sourcing and culinary teams are always looking for
        high-quality options in every aisle to make the whole family happy. That
        includes the staples (like milk and eggs), tons of easy, packable snacks
        (like fruit snacks and pretzels), and quick, crowd-pleasing recipes like
        pesto pasta.
      </>
    ),
  },
  {
    title: 'Where do you deliver?',
    content: (
      <>
        We deliver to most zip codes in the 48 contiguous states and Washington
        D.C. Unfortunately, we don't deliver to Hawaii and Alaska, P.O. Boxes,
        or APO/FPO/DPO. We're working on making it possible to deliver to all
        zip codes across the country in the future!
      </>
    ),
  },
];
